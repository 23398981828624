// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';

// 分页获取数据
export const getRsshList = (params) => {
    return postRequest('/busi/rssh2/getByCondition', params)
}
// 添加
export const addRssh = (params) => {
    return postRequest('/busi/rssh2/insert', params)
}
// 编辑
export const editRssh = (params) => {
    return postRequest('/busi/rssh2/update', params)
}
// 删除
export const deleteRssh = (params) => {
    return postRequest('/busi/rssh2/delByIds', params)
}
// 计算
export const calcGspc = (params) => {
    return postRequest('/busi/rssh2/calc', params)
}

<template>
  <div class="wake-add-edit">
    <!-- Drawer抽屉 -->
    <Drawer :title="title" v-model="visible" width="720" on-close="handleClose()" :mask-closable="false">
      <Form ref="form" :model="form" :rules="formValidate" label-position="top">
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="受害人姓名" prop="xm">
            <Input v-model="form.xm" :maxlength="15" prefix="ios-contact" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="户籍类型" prop="hjlx">
            <Select v-model="form.hjlx">
              <Option v-for="(item, i) in this.$store.state.dict.hjlx" :key="i" :value="item.value">
                {{ item.title }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="受害人年龄" prop="szln">
            <Input v-model="form.szln" :maxlength="3" prefix="ios-person" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="误工天数" prop="wgzts">
            <Input v-model="form.wgzts" :maxlength="3" prefix="md-time" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="年工资标志" prop="gzxz">
            <i-switch v-model="form.gzxz" :true-value="'1'" :false-value="'0'"></i-switch>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="受害人月工资" v-show="form.gzxz == '0'" prop="szygz">
            <Input v-model="form.szygz" :maxlength="10" prefix="logo-bitcoin" />
          </FormItem>
          <FormItem label="受害人年工资" v-show="form.gzxz == '1'" prop="szngz">
            <Input v-model="form.szngz" :maxlength="10" prefix="logo-bitcoin" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="输入伤残系数" prop="scdjxz">
            <i-switch v-model="form.scdjxz" :true-value="'1'" :false-value="'0'"></i-switch>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="伤残等级" prop="scdj" v-show="form.scdjxz == '0'">
            <Select prefix="ios-cube-outline" v-model="form.scdj">
              <Option v-for="(item, i) in this.$store.state.dict.scdj" :key="i" :value="parseInt(item.value)">
                {{ item.title }}</Option>
            </Select>
          </FormItem>
          <FormItem label="伤残系数" prop="scxs" v-show="form.scdjxz == '1'">
            <Input v-model="form.scxs" :maxlength="5" prefix="logo-bitcoin" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="被抚养人1年龄" prop="bfyrnl1">
            <Input v-model="form.bfyrnl1" :maxlength="3" prefix="ios-person" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="被抚养人1人数" prop="bfyrrs1">
            <Select v-model="form.bfyrrs1">
              <Option v-for="(item, i) in this.$store.state.dict.fyrs" :key="i" :value="parseInt(item.value)">
                {{ item.title }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="被抚养人2年龄" prop="bfyrnl2">
            <Input v-model="form.bfyrnl2" :maxlength="3" prefix="ios-person" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="被抚养人2人数" prop="bfyrrs2">
            <Select v-model="form.bfyrrs2">
              <Option v-for="(item, i) in this.$store.state.dict.fyrs" :key="i" :value="parseInt(item.value)">
                {{ item.title }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="被抚养人3年龄" prop="bfyrnl3">
            <Input v-model="form.bfyrnl3" :maxlength="3" prefix="ios-person" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="被抚养人3人数" prop="bfyrrs3">
            <Select v-model="form.bfyrrs3">
              <Option v-for="(item, i) in this.$store.state.dict.fyrs" :key="i" :value="parseInt(item.value)">
                {{ item.title }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="被抚养人4年龄" prop="bfyrnl4">
            <Input v-model="form.bfyrnl4" :maxlength="3" prefix="ios-person" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="被抚养人4人数" prop="bfyrrs4">
            <Select v-model="form.bfyrrs4">
              <Option v-for="(item, i) in this.$store.state.dict.fyrs" :key="i" :value="parseInt(item.value)">
                {{ item.title }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="被抚养人5年龄" prop="bfyrnl5">
            <Input v-model="form.bfyrnl5" :maxlength="3" prefix="ios-person" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="被抚养人5人数" prop="bfyrrs5">
            <Select v-model="form.bfyrrs5">
              <Option v-for="(item, i) in this.$store.state.dict.fyrs" :key="i" :value="parseInt(item.value)">
                {{ item.title }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="医疗费（总）" prop="ylfyze">
            <Input v-model="form.ylfyze" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="住院护理费（总）" prop="hgrgz">
            <Input v-model="form.hgrgz" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="出院护理费（总）" prop="hgrgz2">
            <Input v-model="form.hgrgz2" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="伙食费（总）" prop="mthsf">
            <Input v-model="form.mthsf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="交通费（总）" prop="jtf">
            <Input v-model="form.jtf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="住宿费（总）" prop="zsf">
            <Input v-model="form.zsf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="营养费（总）" prop="mtyyf">
            <Input v-model="form.mtyyf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="精神赔偿费（总）" prop="mjjspc">
            <Input v-model="form.mjjspc" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="残疾辅助用具费（总）" prop="cjfzyjf">
            <Input v-model="form.cjfzyjf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="财产损失费（总）" prop="ccssf">
            <Input v-model="form.ccssf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="后续治疗费（总）" prop="hxzlf">
            <Input v-model="form.hxzlf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="整容费（总）" prop="zrf">
            <Input v-model="form.zrf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="康复费（总）" prop="kff">
            <Input v-model="form.kff" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="丧葬费（总）" prop="szf">
            <Input v-model="form.szf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="其他费用" prop="qtfy">
            <Input v-model="form.qtfy" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
        </Row>

        <Row :gutter="32">
          <Col span="12">
          <FormItem label="省份" prop="province">
            <Select prefix="ios-cube-outline" v-model="form.tmpProvince" @on-change="changeProvince">
              <Option v-for="(item, i) in formExt.listProvince" :key="i" :value="item.id + '|' + item.rownum">
                {{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="城市" prop="city">
            <Select prefix="ios-cube-outline" v-model="form.tmpCity" clearable @on-change="changeCity"
              :disabled="formExt.cityDisabled">
              <Option v-for="(item, i) in formExt.listCity" :key="i" :value="item.id + '|' + item.rownum">
                {{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="统计数据适用年份" prop="sjnf">
            <Select prefix="ios-cube-outline" v-model="form.sjnf" @on-change="changeSjnf">
              <Option v-for="(item, i) in this.$store.state.dict.tjsjnf" :key="i" :value="item.value">
                {{ item.title }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="城镇居民人均可支配收入" prop="czkzpsr">
            <Input v-model="form.czkzpsr" :maxlength="9" prefix="logo-bitcoin" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="城镇居民人均消费支出" prop="czxfzc">
            <Input v-model="form.czxfzc" :maxlength="9" prefix="md-sad" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="农村居民人均年收入" prop="nccsr">
            <Input v-model="form.nccsr" :maxlength="9" prefix="logo-bitcoin" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="农村居民人均年消费支出" prop="ncshzc">
            <Input v-model="form.ncshzc" :maxlength="9" prefix="md-sad" />
          </FormItem>
          </Col>
        </Row>
        <Form-item class="br">
          <Button @click="handleCalc" :loading="submitLoading">计算</Button>
          <Button @click="handleSubmit" :loading="submitLoading">保存</Button>
          <Button @click="handleClose">关闭</Button>
        </Form-item>
        <List :header="formExt.resultHead1" border v-show="formExt.resultVisable" size="small">
          <ListItem v-for="(item, i) in formExt.resultList1" :key="i" :value="i" style="padding-left: 50px">
            {{ item.child }}</ListItem>
        </List>
        <List :header="formExt.resultHead2" border v-show="formExt.resultVisable" size="small">
          <ListItem v-for="(item, i) in formExt.resultList2" :key="i" :value="i" style="padding-left: 50px">
            {{ item.child }}</ListItem>
        </List>
      </Form>
    </Drawer>
  </div>
</template>


<script>
  // 根据你的实际请求api.js位置路径修改
  import {
    addRssh,
    editRssh,
    calcGspc
  } from "@/api/busi/rssh-manage/rssh";
  import {
    getProvince,
    getCity,
    getTjsjArea
  } from "@/api/busi/tjsj-manage/tjsj";
  import {
    validateNumber,
    validateMoney,
    validateXs
  } from "@/libs/validate";
  export default {
    name: "rsshAddEdit",
    components: {},
    props: {
      data: Object,
      addEditFlag: String,
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        submitLoading: false, // 表单提交状态
        title: "人损赔偿",
        visible: this.value,
        form: {
          // 添加或编辑表单对象初始化数据
          xm: "",
          hjlx: "0",
          szln: 0,
          wgzts: 0,
          gzxz: "0",
          szngz: 0,
          szygz: 0,
          scdjxz: "0",
          scdj: 0,
          scxs: 0,
          bfyrnl1: "",
          bfyrrs1: 0,
          bfyrnl2: "",
          bfyrrs2: 0,
          bfyrnl3: "",
          bfyrrs3: 0,
          bfyrnl4: "",
          bfyrrs4: 0,
          bfyrnl5: "",
          bfyrrs5: 0,
          ylfyze: 0,
          hgrgz: 0,
          hgrgz2: 0,
          mthsf: 0,
          jtf: 0,
          zsf: 0,
          mtyyf: 0,
          mjjspc: 0,
          cjfzyjf: 0,
          ccssf: 0,
          hxzlf: 0,
          zrf: 0,
          kff: 0,
          szf: 0,
          qtfy: 0,
          province: "",
          city: "",
          sjnf: "0",
          czkzpsr: 0,
          czxfzc: 0,
          nccsr: 0,
          ncshzc: 0,
          ze: 0,
          provinceCode: "",
          cityCode: "",
          tmpProvince: "",
          tmpCity: "",
        },
        formExt: {
          listProvince: [],
          listCity: [],
          cityDisabled: true,
          resultVisable: false,
        },
        // 表单验证规则
        formValidate: {
          xm: [{
            required: true,
            message: "不能为空",
            trigger: "blur",
          }, ],
          szln: [{
            validator: validateNumber,
            trigger: "blur",
          }, ],
          wgzts: [{
            validator: validateNumber,
            trigger: "blur",
          }, ],
          bfyrnl1: [{
            validator: validateNumber,
            trigger: "blur",
          }, ],
          bfyrnl2: [{
            validator: validateNumber,
            trigger: "blur",
          }, ],
          bfyrnl3: [{
            validator: validateNumber,
            trigger: "blur",
          }, ],
          bfyrnl4: [{
            validator: validateNumber,
            trigger: "blur",
          }, ],
          bfyrnl5: [{
            validator: validateNumber,
            trigger: "blur",
          }, ],
          scxs: [{
            validator: validateXs,
            trigger: "blur",
          }, ],
          szngz: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          szygz: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          ylfyze: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          hgrgz: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          hgrgz2: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          mthsf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          jtf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          zsf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          mtyyf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          mjjspc: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          cjfzyjf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          ccssf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          hxzlf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],

          zrf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          kff: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          szf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          qtfy: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          czkzpsr: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          czxfzc: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          nccsr: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          ncshzc: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
        },
      };
    },
    methods: {
      init() {
        // 计算结果不可见
        this.formExt.resultVisable = false;

        // 省份结果集获取
        if (this.formExt.listProvince.length == 0) {
          getProvince().then((res) => {
            this.submitLoading = false;
            if (res.success) {
              this.formExt.listProvince = res.result;
            }
          });
        }

        // 编辑情况下
        if (this.addEditFlag == "2") {
          this.form = this.data;
          this.form.hjlx = this.form.hjlx.toString();
          this.form.gzxz = this.form.gzxz.toString();
          this.form.scdjxz = this.form.scdjxz.toString();
          this.form.scdj = this.form.scdj;
          this.form.sjnf = this.form.sjnf.toString();
          // this.form.bfyrrs1 = this.form.bfyrrs1.toString();
          // this.form.bfyrrs2 = this.form.bfyrrs2.toString();
          // this.form.bfyrrs3 = this.form.bfyrrs3.toString();
          // this.form.bfyrrs4 = this.form.bfyrrs4.toString();
          // this.form.bfyrrs5 = this.form.bfyrrs5.toString();
          if (this.form.province != "") {
            this.form.provinceCode =
              this.formExt.listProvince[this.form.province - 1].id;
            this.form.tmpProvince =
              this.form.provinceCode + "|" + this.form.province;
          }
          if (this.form.city != "") {
            getCity({
              province: this.form.provinceCode,
            }).then((res) => {
              this.submitLoading = false;
              if (res.success) {
                this.formExt.listCity = res.result;
                if (this.formExt.listCity != 0) {
                  this.formExt.cityDisabled = false;
                }
                this.form.cityCode = this.formExt.listCity[this.form.city - 1].id;
                this.form.tmpCity = this.form.cityCode + "|" + this.form.city;
              }
            });
          }
        } else {
          delete this.form.id;
          this.form.city ="";
          this.form.cityCode ="";
          this.form.province = "";
          this.form.provinceCode = "";
          this.form.tmpCity = "";
          this.form.tmpProvince = "";
        }
      },
      changeProvince() {
        if (
          this.form.tmpProvince == null ||
          this.form.tmpProvince == "" ||
          this.form.tmpProvince == undefined
        ) {
          return;
        }
        this.form.provinceCode = this.form.tmpProvince.split("|")[0];
        this.form.province = this.form.tmpProvince.split("|")[1];
        getCity({
          province: this.form.provinceCode,
        }).then((res) => {
          this.submitLoading = false;
          if (res.success) {
            this.formExt.listCity = res.result;
            if (this.formExt.listCity == 0) {
              this.form.tmpCity = "";
              this.form.city = "";
              this.form.cityCode = "";
              this.formExt.cityDisabled = true;
              this.getTjsj();
            } else {
              this.formExt.cityDisabled = false;
              this.form.tmpCity = "";
              this.form.city = "";
              this.form.cityCode = "";
              this.form.czkzpsr = 0;
              this.form.czxfzc = 0;
              this.form.nccsr = 0;
              this.form.ncshzc = 0;
            }
          }
        });
      },
      changeCity() {
        if (
          this.form.tmpCity == null ||
          this.form.tmpCity == "" ||
          this.form.tmpCity == undefined
        ) {
          return;
        }
        this.form.cityCode = this.form.tmpCity.split("|")[0];
        this.form.city = this.form.tmpCity.split("|")[1];
        this.getTjsj();
      },
      changeSjnf() {
        this.getTjsj();
      },
      getTjsj() {
        if (this.form.province == undefined || this.form.province == "") return;
        if (
          (this.form.city == undefined || this.form.city == "") &&
          this.formExt.cityDisabled == false
        )
          return;
        if (this.form.sjnf == undefined || this.form.sjnf == "") return;

        var param = {
          province: this.form.provinceCode,
          city: this.form.cityCode,
          sjnf: this.$store.state.dict.tjsjnf[this.form.sjnf-1].title,
        };
        getTjsjArea(param).then((res) => {
          this.submitLoading = false;
          if (res.success) {
            this.form.czkzpsr = res.result.czkzpsr;
            this.form.czxfzc = res.result.czxfzc;
            this.form.nccsr = res.result.nccsr;
            this.form.ncshzc = res.result.ncshzc;
          }
        });
      },
      setCurrentValue(value) {
        if (value === this.visible) {
          this.$refs.form.resetFields();
          return;
        }
        this.init();
        this.visible = value;
      },
      handleReset() {
        this.$refs.form.resetFields();
      },
      dynCheck(){
          if (this.form.province==undefined || this.form.province=="") {
            this.$Message.warning("请选择省份");
            return false;
          }
          if (this.form.sjnf==undefined || this.form.sjnf==""  || this.form.sjnf=="0") {
            this.$Message.warning("请选择数据年份");
            return false;
          }
          return true;
      },
      handleSubmit() {
        if (this.form.bfyrrs1=='') this.form.bfyrrs1=0;
        if (this.form.bfyrrs2=='') this.form.bfyrrs2=0;
        if (this.form.bfyrrs3=='') this.form.bfyrrs3=0;
        if (this.form.bfyrrs4=='') this.form.bfyrrs4=0;
        if (this.form.bfyrrs5=='') this.form.bfyrrs5=0;

        if (this.dynCheck()==false) {
           return;
        }
       this.$refs.form.validate((valid) => {
          if (valid) {
            // 工资类型
            if (this.form.gzxz == "0") {
              this.form.szngz = 0;
            } else {
              this.form.szygz = 0;
            }
            // 伤残等级or伤残系数
            if (this.form.scdjxz == "0") {
              this.form.scxs = 0;
            } else {
              this.form.scdj = 0;
            }

            // 防止下拉框有undefined存在
            for (var k in this.form) {
              if (this.form[k] == undefined) this.form[k] = "";
            }

          if (this.addEditFlag == "1") {
              addRssh(this.form).then((res) => {
                this.submitLoading = false;
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.$emit("on-submit", true);
                  this.visible = false;
                }
              });
            } else {
              editRssh(this.form).then((res) => {
                this.submitLoading = false;
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.$emit("on-submit", true);
                  this.visible = false;
                }
              });
            }
          }else{
            this.$Message.info("请检查界面输入信息");
          }
        });
      },
      handleCalc() {
        if (this.form.bfyrrs1=='') this.form.bfyrrs1=0;
        if (this.form.bfyrrs2=='') this.form.bfyrrs2=0;
        if (this.form.bfyrrs3=='') this.form.bfyrrs3=0;
        if (this.form.bfyrrs4=='') this.form.bfyrrs4=0;
        if (this.form.bfyrrs5=='') this.form.bfyrrs5=0;
       if (this.dynCheck()==false) {
           return;
        }
        // 防止下拉框有undefined存在
        for (var k in this.form) {
          if (this.form[k] == undefined) this.form[k] = "";
        }
        calcGspc(this.form).then((res) => {
          this.submitLoading = false;
          if (res.success) {
            this.formExt.resultHead1 = res.result[0];
            this.formExt.resultList1 = res.result[1];
            this.form.ze = res.result[4];
            this.formExt.resultHead2 = res.result[2];
            this.formExt.resultList2 = res.result[3];
            this.formExt.resultVisable = true;
          }
        });
      },
      handleClose() {
        this.visible = false;
      },
    },
    watch: {
      value(val) {
        this.setCurrentValue(val);
      },
      visible(value) {
        this.$emit("input", value);
      },
    },
    mounted() {
      this.init();
    },
  };
</script>

<style lang="less">
  // @import "../../../styles/table-common.less";
  .drawer-content {
    overflow: auto;
  }

  .drawer-content::-webkit-scrollbar {
    display: none;
  }

  .user-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .info-title {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      display: block;
      margin-right: 16px;
    }
  }

  .info-header {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    display: block;
    margin-bottom: 16px;
  }
</style>